import React, {useState} from 'react'
import { PageTemplate } from '../templates/page'
import { destroy } from 'cart-localstorage'
import { navigate } from 'gatsby'
import Button from '../components/shortcodes/Button'
import GetQueryParameter from '../scripts/get-query-parameter'

const meta = {
  pageIdentifier: "order-confirmation",
  pageClass: "hide-cart has-text-centered",
  showCta: false,
  showContactForm: false
}

async function sendEmails(template, cart, metadata, pathname, orderID) {
  await fetch('/.netlify/functions/send-mail', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'template': template,
      'cart': cart,
      'metadata': metadata
    }),
  }).then(() => navigate( pathname + '?success=true&order_id=' + orderID ) )
}

const OrderConfirmationPage = ( {location} ) => {
  let orderID = GetQueryParameter('order_id', location.href)
  let successParam = GetQueryParameter('success', location.href)
  let cartParam = GetQueryParameter('cart', location.href)

  const [emailsSent, setEmailsSent] = useState(false)

  // It's an order Thank You page
  if ( orderID && successParam ) {
    // Delete products from cart
    destroy()

    return (
      <PageTemplate 
        title="Order Received!"
        subtitle={"Your Order reference is <strong>" + orderID + "</strong> and we have sent you a receipt by e-mail.<br />Your order will be ready for you on the date you chose.<br />If you have any questions, please contact us."}
        pageIdentifier={meta.pageIdentifier}
        pageClass={meta.pageClass}
        showCta={meta.showCta}
        showContactForm={meta.showContactForm}
      >
        <Button url="/" text="Go Back" />
      </PageTemplate>
    )
  } else if (orderID && cartParam) {
    if ( !emailsSent ) {
      // Prepare and send emails
      let cart = []
      cartParam = cartParam.split('||')

      Object.keys(cartParam).map(item => {
        let itemSplit = cartParam[item].split('|')

        cart[item] = {
          "name": itemSplit[0],
          "quantity": itemSplit[1],
          "price": itemSplit[2],
        }

        return false
      })

      sendEmails('orderSuccessToCustomer', cart, location.search, location.pathname, orderID)
      setEmailsSent(true)
    }

    return (
      <PageTemplate 
        title="Please wait..."
        subtitle="We are processing your payment and will redirect you shortly.<br /> Please <strong>do not</strong> navigate away from this page..."
        pageIdentifier={meta.pageIdentifier}
        pageClass={meta.pageClass}
        showCta={meta.showCta}
        showContactForm={meta.showContactForm}
      />
    )
  }
  // Error fallback - This is also a cached version shown for a split second before other states are processed
  else {
    return (
      <PageTemplate 
        title="Please wait..."
        subtitle="Loading..."
        pageIdentifier={meta.pageIdentifier}
        pageClass={meta.pageClass}
        showCta={meta.showCta}
        showContactForm={meta.showContactForm}
      />
    )
  }
}

export default OrderConfirmationPage